
export const head_text = [
  '',
  {
    title: '今日服药提醒',
    sub: '定时吃药，是您康复必要条件',
  },
  {
    title: '就诊提醒',
    sub: '按时就诊能让医生治疗更有效',
  },
  {
    title: '复诊提醒',
    sub: '按时复诊能让医生治疗更有效',
  },
  '',
  {
    title: '检验提醒',
    sub: '检验是诊断疾病的重要依据',
  },
  {
    title: '检查提醒',
    sub: '检查是诊断疾病的重要依据',
  },
]


