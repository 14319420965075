import api from '../../utils/api'
// 登录
export async function get_list (id, payload) {
  // console.log(id,payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         person: {
  //           name: '王勇恒',
  //           relationship_type: '1',
  //           relationship_label: '夫妻',
  //         },
  //         diagnosis: {
  //           department_name: '心外科',
  //           department_code: '80056',
  //           hospital_id: 1,
  //           doctor_code: 578,
  //           visiting_time: new Date(),
  //         },
  //         exam: {
  //           desc: '按时复诊能让医生治疗更有效',
  //           title: '肠镜检查提醒',
  //           article_id: 1,
  //         },
  //         drugs: [
  //           {
  //             name: '头孢拉丁缓释片0.5mg*20',
  //             freq_name: '口服',
  //             usage: '每日二次 一次0.5mg/片',
  //           },
  //           {
  //             name: '缓释片0.5mg*20',
  //             freq_name: '注射',
  //             usage: '每日二次 一次0.5mg/瓶',
  //           },
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/reminds/${id}`, {params:payload})
}

export async function get_text (id, payload) {
  return api.get(`/api/v1/mp/inquiries/session/articles/${id}`, {params: payload})
}

export async function cancel_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {}
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/reminds/${payload}`)
}


