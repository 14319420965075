<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="remind_head" v-if="type !== 4">
        <div class="remind_title">
          {{ head_text[type].title }}
        </div>
        <div class="remind_sub">
          {{ head_text[type].sub }}
        </div>
      </div>

      <div class="remind_head" v-if="data && type === 4">
        <div class="remind_title">
          {{ data.exam.title }}
        </div>
        <div class="remind_sub">
          {{ data.exam.desc }}
        </div>
      </div>
      <div class="remind_center" v-if="data">
        <div class="health_assist">
          <img
            src="../../assets/img/home/img-ai-logos@3x.png"
            alt=""
            width="60"
          />
          <div class="health_box">
            <div class="health_title">健康助手</div>
            <div>全天候守护您的家庭健康</div>
          </div>
          <div class="health_btn">向我提问</div>
        </div>
        <div class="remind_content" v-if="type === 1">
          <p>
            <span class="remind_blue">
              {{ data.person.name }} ({{ data.person.relationship_label }})
            </span>
            您今天服药信息如下：
          </p>
          <div
            class="remind_med"
            v-for="(item, index) in data.drugs"
            :key="index"
          >
            <p class="remind_blue">{{ item.name }}/{{ item.freq_name }}</p>
            {{ item.usage }}
          </div>
        </div>
        <div class="remind_content" v-if="type === 2">
          <span class="remind_blue"> {{ data.person.name }} </span>请您于<span
            class="remind_blue"
          >
            {{ format_date(data.diagnosis.visiting_time, "yyyy年MM月dd日") }}
            {{ data.diagnosis.section }} </span
          >到
          <span class="remind_blue"> {{ data.diagnosis.department_name }} </span
          >进行就诊, 隔日预约无效。
        </div>
        <div class="remind_content" v-if="type === 3">
          <span class="remind_blue">
            {{ data.person.name }} ({{ data.person.relationship_label }}) </span
          >距离您
          <span class="remind_blue"> {{ data.diagnosis.department_name }} </span
          >上次就诊已经过去7天了。建议您进行复诊
          <div class="remind_btn_out">
            <router-link
              :to="{
                path: '/doctor-page',
                query: {
                  doctor_id: data.diagnosis.doctor_id,
                  hospital_id: data.diagnosis.hospital_id,
                  department_code: data.diagnosis.department_code,
                  department_name: data.diagnosis.department_name,
                },
              }"
            >
              <div class="remind_btn">预约挂号</div>
            </router-link>
            <!-- <router-link :to="{
            path: '/online-inquiry-detail',
            query: {
              id: data.diagnosis.doctor_code
            },
          }">
            <div class="remind_btn">
              在线问诊
            </div>
          </router-link> -->
          </div>
        </div>
        <!-- 文章模块 -->
        <div v-if="type === 4 && article">
          <div class="remind_content">
            {{ article.title }}
          </div>
          <div class="remind_article" v-html="article.content"></div>
          <div class="remind_article_img" v-if="article.url">
            <img :src="article.url" alt="" width="80%" />
          </div>
        </div>

        <div class="remind_content" v-if="type === 5 || type === 6">
          请您于{{
            format_date(data.lab_examines.visiting_time, "yyyy年MM月dd日")
          }}
          {{ data.lab_examines.section }} 至
          <span class="remind_blue"
            >罗湖中心医院{{ data.lab_examines.department_name }}</span
          >
          进行{{ type === 5 ? "检验" : "检查" }}

          <div style="padding-top: 20px">
            <div
              class="remind_blue"
              style="margin-bottom: 6px"
              v-for="(item, index) in data.lab_examines
                .laboratory_and_examine_names"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>

        <div class="remind_next" v-if="type === 1">
          <div class="remind_warn">警</div>
          <div class="warn_text">如您已服药请忽略本次提醒,不要重复服药</div>
        </div>
      </div>
      <div class="remind_cancel" @click="cancel_remind">
        <van-icon name="clock-o" />
        <p>取消提醒</p>
      </div>
    </div>
  </div>
</template>

<script>
import { head_text } from "./config"
import { get_list, get_text, cancel_list } from "./service"
import { format_date } from "../../utils/format"
import { Dialog } from "vant"
export default {
  data() {
    return {
      loading: false,
      type: null,
      id: null,
      head_text,
      data: null,
      article: null,
    }
  },
  computed: {},
  components: {},
  created() {
    let query = this.$route.query
    this.type = parseInt(query.type)
    this.id = parseInt(query.id)
    this.get_detail()
  },
  methods: {
    format_date,
    async get_detail() {
      this.loading = true
      try {
        const { data } = await get_list(this.id, {
          remind_type: this.type,
        })
        this.data = data
        if (this.type === 4) {
          let query = {}
          let arr = this.url.split("&")
          arr.forEach((item) => {
            let el = item.split("=")
            query[el[0]] = el[1]
          })
          query.encry = GLOBAL_CONFIG.article_encry
          let id = query.id
          delete query.id
          this.get_article(id, query)
        }
      } finally {
        this.loading = false
      }
    },
    async get_article(id, payload) {
      this.loading = true
      try {
        const { data } = await get_text(id, payload)
        this.article = data
      } finally {
        this.loading = false
      }
    },
    cancel_remind() {
      Dialog.confirm({
        title: "提示",
        message: "请确认是否取消当前提醒？",
        confirmButtonText: "确认",
        cancelButtonText: "再等等",
      })
        .then(() => {
          this.cancel_order()
        })
        .catch(() => {
          // on cancel
        })
    },
    async cancel_order() {
      this.loading = true
      try {
        await cancel_list(this.id)
        this.$notify({ type: "success", message: "取消成功" })
        this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.g_page_box {
  background: #fff;
}

.remind_head {
  padding: 25px 16px;
  background: url("../../assets/img/family-bg.png") right center no-repeat
    #000ca8;
  background-size: contain;
  color: #ffffff;
  font-size: 12px;
  height: 120px;
}

.remind_title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.remind_sub {
  font-size: 12px;
  line-height: 17px;
}

.remind_center {
  padding: 20px 18px;
}

.health_assist {
  display: flex;
  align-items: center;

  & img {
    border-radius: 50%;
  }
}

.health_box {
  flex: 1;
  margin-left: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #565656;
}

.health_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.health_btn {
  width: 76px;
  height: 28px;
  background: #0088ff;
  border-radius: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
}

.remind_content {
  padding: 20px 14px;
  background: #f7faff;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #222222;
  letter-spacing: -0.5px;

  &::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: -8px;
    left: 21px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #f7faff;
  }
}

.remind_blue {
  color: #008fff;
}

.remind_med {
  margin-top: 14px;
  font-size: 14px;
  line-height: 20px;
  color: #919191;
}

.remind_next {
  height: 31px;
  background: #ffffe3;
  opacity: 1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-top: 14px;
  font-size: 14px;
  color: #565656;
}

.remind_warn {
  width: 21px;
  height: 21px;
  background: linear-gradient(229deg, #ffd09a 0%, #ff3100 100%);
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
  margin-right: 6px;
}

.remind_cancel {
  position: fixed;
  right: 18px;
  bottom: 20px;
  display: flex;
  align-items: center;
  color: #008fff;
  font-size: 14px;
  line-height: 20px;

  & p {
    padding-left: 5px;
  }
}

.remind_btn_out {
  padding-top: 19px;
  display: flex;
  align-content: center;
}

.remind_btn {
  width: 76px;
  height: 28px;
  border: 1px solid #0088ff;
  opacity: 1;
  border-radius: 38px;
  font-size: 14px;
  color: #0088ff;
  text-align: center;
  margin-right: 10px;
}

.remind_article {
  padding-top: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
}

.remind_article_img {
  text-align: center;
}
</style>
